import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import FullImageHero from '../../components/full-image-hero'
import { useMemo } from 'react'
import tw, { css } from 'twin.macro'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Text from '../../components/text'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'
import PatternMesh from '../../images/pattern-mesh.svg'
import UseCaseCards from '../../components/use-case-cards'

const IndexPage = ({ data: { index }, location }) => {
  const page = useMemo(
    () =>
      flatten(index || {}, [
        'hero',
        'about',
        'whyBolttechHeader',
        'informationHeader',
      ]),
    [index]
  )

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} headerTransition>
      <section id="hero" css={tw`relative bg-grey-light`}>
        <FullImageHero
          image={get(page, 'hero.image')}
          imageMobile={get(page, 'hero.imageMobile')}
          title={get(page, 'hero.titleNode')}
          style={tw`lg:(mb-0 pb-52)`}
          extended
        />
      </section>
      <section id="about" css={tw`relative bg-grey-light pt-24 pb-16 lg:(pt-40 pb-24)`}>
        <div css={[global`layout.container`, global`layout.grid`, tw`relative items-start z-1`]}>
          <div
            css={tw`col-span-4 md:col-span-8 lg:(col-start-2 col-end-13) xl:(col-start-6 col-end-13)`}
          >
            <Heading
              headingType="h3"
              content={get(page, 'about.titleNode')}
              style={css`
                ${tw`text-primary-500`}
                strong {
                  ${tw`font-medium`}
                }
              `}
            />
          </div>
          <div
            css={tw`col-span-4 mt-6 flex flex-col items-start md:col-span-8
            lg:(col-start-2 col-end-8 mt-8) xl:(col-start-6 col-end-12)`}
          >
            <Text
              content={get(page, 'about.descriptionNode')}
              style={css`
                ${tw`text-primary-500`}
                p {
                  ${tw`mb-8 last-of-type:mb-0`}
                }
              `}
            />
          </div>
        </div>
      </section>
      <section id="why" css={tw`relative pt-24 pb-12 lg:(pt-30 pb-28)`}>
        <div css={[global`layout.container`, global`layout.grid`]}>
          <div css={tw`col-span-4 z-1 md:col-span-8 lg:col-span-12 xl:col-span-16`}>
            <div css={tw`flex flex-col items-center justify-center lg:(w-2/3 mx-auto)`}>
              <Heading
                headingType="h2"
                content={get(page, 'whyBolttechHeader.title')}
                style={tw`font-bold text-center text-primary-500`}
              />
            </div>
            <div css={tw`flex flex-col items-center justify-center mt-12 lg:(mt-6 w-1/2 mx-auto)`}>
              <Text
                content={get(page, 'whyBolttechHeader.descriptionNode')}
                css={tw`font-normal text-center text-primary-500`}
                textType="body-lg"
              />
            </div>
          </div>
          <div css={tw`col-span-4 z-1 mt-28 md:col-span-8 lg:(col-span-12 mt-46) xl:col-span-16`}>
            <UseCaseCards useCases={get(page, 'whyBolttechCards')} />
          </div>
        </div>
        <div
          css={css`
            ${tw`absolute bottom-0 left-0 right-0 h-full bg-bottom`}
            background-image: url('${PatternMesh}');
          `}
        />
      </section>
      <section id="contact" css={tw`bg-primary-50`}>
          <div css={[global`layout.container`, global`layout.grid`, tw`py-20 lg:(pt-20 pb-44)`]}>
          <div css={tw`col-span-4 z-1 md:col-span-8 lg:col-span-12 xl:col-span-16`}>
              <div
                css={tw`flex col-span-4 md:col-span-8 lg:(col-span-12) xl:col-span-16`}
              >
                <Heading 
                  headingType="h2"
                  content={get(page, 'informationHeader.titleNode')} 
                  style={tw`items-start font-bold text-primary-500`} />
              </div>
              <div css={tw`flex flex-col mt-4 mb-14 lg:(mt-6 w-1/2)`}>
                <Text
                  content={get(page, 'informationHeader.descriptionNode')}
                  css={tw`font-normal items-start text-primary-500`}
                  textType="body-lg"
                />
              </div>
            </div>
            <div
              css={tw`flex flex-col col-span-4 md:col-span-8 space-y-6 items-start lg:(mt-20 col-start-2 col-end-12 justify-center space-x-16 space-y-0 flex-row) xl:col-end-16`}
            >
              {get(page, 'information').map((company, i) => (
                <div
                  key={i}
                  css={tw`flex flex-1 flex-col bg-primary-100 last-of-type:bg-grey-purple items-start px-6 py-16 lg:(items-center justify-between first-of-type:-pb-40 pt-20 pb-24 px-16)`}
                >
                  <div css={tw`flex flex-col lg:items-start`}>
                    <Heading headingType="h4" content={company.titleNode} style={tw`font-bold`} />
                    <Text content={company.descriptionNode} style={tw`mt-6 lg:items-start`} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    index: PropTypes.object.isRequired,
  }),
  location: PropTypes.object,
}

export default IndexPage

export const query = graphql`
query IndexQuery($locale: String!) {
  index: datoCmsBrokerageEuHome(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        image {
          format
          url
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
      about {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }  
      whyBolttechHeader {
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      whyBolttechCards {
        image {
          format
          url
        }
        title
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }   
      informationHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      information {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      } 
    }
  }
`
